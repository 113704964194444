// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsbywpthemes-gatsby-theme-blog-data-src-templates-page-query-js": () => import("./../../../node_modules/@gatsbywpthemes/gatsby-theme-blog-data/src/templates/page-query.js" /* webpackChunkName: "component---node-modules-gatsbywpthemes-gatsby-theme-blog-data-src-templates-page-query-js" */),
  "component---node-modules-gatsbywpthemes-gatsby-theme-wp-base-src-gatsbywpthemes-gatsby-theme-blog-data-templates-category-query-js": () => import("./../../../node_modules/@gatsbywpthemes/gatsby-theme-wp-base/src/@gatsbywpthemes/gatsby-theme-blog-data/templates/category-query.js" /* webpackChunkName: "component---node-modules-gatsbywpthemes-gatsby-theme-wp-base-src-gatsbywpthemes-gatsby-theme-blog-data-templates-category-query-js" */),
  "component---node-modules-gatsbywpthemes-gatsby-theme-wp-base-src-gatsbywpthemes-gatsby-theme-blog-data-templates-post-query-js": () => import("./../../../node_modules/@gatsbywpthemes/gatsby-theme-wp-base/src/@gatsbywpthemes/gatsby-theme-blog-data/templates/post-query.js" /* webpackChunkName: "component---node-modules-gatsbywpthemes-gatsby-theme-wp-base-src-gatsbywpthemes-gatsby-theme-blog-data-templates-post-query-js" */),
  "component---node-modules-gatsbywpthemes-gatsby-theme-wp-base-src-gatsbywpthemes-gatsby-theme-blog-data-templates-posts-query-js": () => import("./../../../node_modules/@gatsbywpthemes/gatsby-theme-wp-base/src/@gatsbywpthemes/gatsby-theme-blog-data/templates/posts-query.js" /* webpackChunkName: "component---node-modules-gatsbywpthemes-gatsby-theme-wp-base-src-gatsbywpthemes-gatsby-theme-blog-data-templates-posts-query-js" */),
  "component---node-modules-gatsbywpthemes-gatsby-theme-wp-base-src-gatsbywpthemes-gatsby-theme-blog-data-templates-user-query-js": () => import("./../../../node_modules/@gatsbywpthemes/gatsby-theme-wp-base/src/@gatsbywpthemes/gatsby-theme-blog-data/templates/user-query.js" /* webpackChunkName: "component---node-modules-gatsbywpthemes-gatsby-theme-wp-base-src-gatsbywpthemes-gatsby-theme-blog-data-templates-user-query-js" */),
  "component---node-modules-gatsbywpthemes-gatsby-theme-wp-base-src-pages-404-js": () => import("./../../../node_modules/@gatsbywpthemes/gatsby-theme-wp-base/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsbywpthemes-gatsby-theme-wp-base-src-pages-404-js" */)
}

