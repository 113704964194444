module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://vrbasics.bryanheadrick.com/graphql","excludeFieldNames":["blocksJSON","saveContent"],"presets":[{"presetName":"DEVELOP","options":{"type":{"__all":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true}}],"verbose":true},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-theme-blog-data/gatsby-browser.js'),
      options: {"plugins":[],"layoutWidth":{"page":"lg","post":"lg","archive":"lg"},"webfontsOptions":{"fonts":{"google":[{"family":"Baumans"},{"family":"Coda"}]}},"overrideWPColors":true,"wordPressUrl":"https://vrbasics.bryanheadrick.com"},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-plugin-wordpress-lightbox/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-plugin-gwpt-packages/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://vrbasics.bryanheadrick.com","pathPrefix":"","paginationPrefix":"page","addWordPressComments":true,"addWordPressSearch":false,"gaTrackingId":null,"gaOptions":{},"googleTagManagerId":null,"googleTagManagerOptions":{},"addSiteMap":false,"siteMapOptions":{},"widgetAreas":{"slideMenuWidgets":["Categories","RecentPosts","Tags","SocialFollow"],"sidebarWidgets":["Categories","RecentPosts","Tags","SocialFollow"]},"archiveSidebarPosition":"right","pageCreateDebugOutput":false,"logo":null,"darkModeLogo":null,"socialFollowLinks":[],"overrideWPColors":true,"developLimit":null,"addColorModes":true,"webfontsOptions":{"fonts":{"google":[{"family":"Baumans"},{"family":"Coda"}]}},"gatsbySourceWordPressOptions":{"presets":[]},"createPages":true,"createPosts":true,"createCategories":true,"createTags":true,"createUsers":true,"withApollo":true,"layoutWidth":{"page":"lg","post":"lg","archive":"lg"},"fonts":[]},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-theme-wp-base/gatsby-browser.js'),
      options: {"plugins":[],"layoutWidth":{"page":"lg","post":"lg","archive":"lg"},"webfontsOptions":{"fonts":{"google":[{"family":"Baumans"},{"family":"Coda"}]}},"overrideWPColors":true,"wordPressUrl":"https://vrbasics.bryanheadrick.com"},
    }]
